<template>
    <v-autocomplete v-if="!combobox" v-bind="$attrs" v-on="$listeners" :items="options" v-model="modelValue" :loading="isLoading" :disabled="disabled"></v-autocomplete>
    <v-combobox v-else v-bind="$attrs" v-on="$listeners" :items="options" :value="modelValue" :search-input="modelValue" @update:search-input="handleSearchInput" :loading="isLoading" :disabled="disabled"></v-combobox>
</template>

<script>
    export default {
        name: "FetchOptionsSelect",
        inheritAttrs: false,
        props: {
            action: {
                type: String,
                required: true
            },
            combobox: {
                type: Boolean,
                default: false
            },
            actionParameter: null,
            value: null,
            translationFunction: Function,
            disabled: {
                type: Boolean,
                default: false
            },
        },
        model: {
            prop: 'value',
            event: 'valueChange'
        },
        data() {
            return {
                options: null,
                isLoading: false,
            }
        },
        created() {
            this.fetchData()
        },
        methods: {
            handleSearchInput(input) {
                this.modelValue = input
            },
            fetchData() {
                if (!this.disabled) {
                    this.isLoading = "green"
                    this.$store.dispatch(this.action, this.actionParameter).then((result) => {
                        if (this.translationFunction) {
                            this.options = result.map((option) => ({text: this.translationFunction(option), value: option}))
                        } else {
                            this.options = result
                        }
                    })
                    .catch(() => this.isLoading = "error") 
                    .finally(() => this.isLoading = false) 
                }
            }
        },
        computed: {
            modelValue: {
                get() {
                    return this.value
                },
                set(newValue) {
                    this.$emit('valueChange', newValue)
                }
            }
        },
        watch: {
            actionParameter() {
                this.fetchData()
            }
        }
    }

</script>